import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Styles
import { outer } from 'styles';

// Layouts
import { PageLayout, SEOContent, SingleHOFLayout } from 'layouts';

// Hooks
import { useDefaultManProfileImage, useDefaultWomanProfileImage } from 'hooks';

// Templates
import { BasicTemplate, ImageMap } from 'templates';

// Types
import { HOF } from 'src/types';
interface Props {
  data: {
    hofEntry: {
      data: HOF;
    };
  };
}

export default ({ data }: Props) => {
  const gender = get(data, 'hofEntry.data.gender', '') || '';
  const fullName = get(data, 'hofEntry.data.full_name', '') || '';
  const events = get(data, 'hofEntry.data.events', []) || [];
  const school = get(data, 'hofEntry.data.school', '') || '';

  // Set up a fallback profile picture if one is not defined.
  const profileImageMap: ImageMap = {
    Male: useDefaultManProfileImage().src,
    Female: useDefaultWomanProfileImage().src,
  };
  const fallbackProfileImage = profileImageMap[gender as keyof ImageMap];
  const dataWithFallback = { ...data.hofEntry.data, fallbackProfileImage };
  const subtitles = [
    school,
    `${events.length ? ' | ' : ''}`,
    `${events.join(', ')}`,
  ];

  return (
    <SEOContent title="Hall of fame">
      <PageLayout>
        <BasicTemplate templateTitle={fullName} subTitles={subtitles}>
          <main css={[outer]}>
            <SingleHOFLayout data={dataWithFallback} />
          </main>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

// Query param is available in gatsby-node.
export const query = graphql`
  query MyQuery($hofId: Int, $hofType: String) {
    hofEntry: airtable(
      table: { eq: "Hall Of Fame" }
      data: { hof_id: { eq: $hofId }, hall_of_fame_type: { eq: $hofType } }
    ) {
      id
      data {
        full_name
        gender
        bio
        events
        school
        class_year
        induction_year
        attachments {
          localFiles {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
